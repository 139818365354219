@import "../node_modules/@angular/material/prebuilt-themes/azure-blue.css";
@import "../node_modules/primeng/resources/themes/mdc-light-indigo/theme.css";
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeng/resources/primeng.css";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/primeflex/primeflex.css";

/* Globally reduce PrimeNG component sizes */
:root {
  --font-size: 1rem;
  /* Set smaller font size */
  --input-padding: 0.75rem 0.75rem;
  /* Condensed padding for inputs */
  --button-padding: 0.643rem 0.75rem;
  /* Condensed padding for buttons */
  --height: fit-content;
}

:root {
  --primary-color: #368ede;
  /* Replace with your desired primary color */
  --mdc-theme-primary-light: #FF8A50;
  /* Optional: Lighter shade */
  --mdc-theme-primary-dark: #D84315;
  /* Optional: Darker shade */
}

.p-button-primary {
  background: #368EDE
}

.p-button-primary:hover {
  background: #162A3E
}

.p-button-danger {
  background: #DC6868
}

.p-float-label textarea.p-inputtext:focus + label, .p-float-label textarea.p-inputtext.p-focus + label {
  color: #368EDE;
}

.p-dropdown.p-focus {
  border-color: #368EDE;
  box-shadow: inset 0 0 0 1px #368EDE;
}

.p-inputtext:focus {
  border-color: #368EDE;
  box-shadow: inset 0 0 0 1px #368EDE;
}

.p-toolbar {
  padding: 0.75rem 0px;
}

.p-button.p-button-danger.p-button-text:not(:disabled) {
  color: white;
}

.p-button.p-button-danger.p-button-text:not(:disabled):hover {
  color: white;
}

.p-button.p-button-primary.p-button-text:not(:disabled) {
  color: white;
}

.p-button.p-button-primary.p-button-text:not(:disabled):hover {
  color: white;
}

.p-button-warning {
  background: #FFD34F
}

.p-button.p-button-danger.p-button-outlined, .p-button-group.p-button-danger > .p-button.p-button-outlined, .p-splitbutton.p-button-danger > .p-button.p-button-outlined {
  background-color: transparent;
  color: #DC6868;
  border-width: 1px;
  border-style: solid;
  border-color: #DC6868;
  border-image: initial;
}


.mat-expansion-panel-header {
  padding: 0px !important;
  background: #162a3e;
}

.mat-expansion-panel {
  background: #162a3e!important;
}


html {
  font-family: "franklin-gothic-condensed";
}

.p-toolbar{
    background: none;
}

.p-sidebar-right {
  width: 42rem;
  top: 4rem;
  padding:4px;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: var(--primary-blue);
  background: var(--primary-blue);
}

/*.p-button {
  background: var(--primary-blue);
  color:white
}*/

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: rgba(63, 81, 181, 0.12);
  border-color: rgba(63, 81, 181, 0.12);
  color: var(--primary-blue);
}

.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.12);
  transition-duration: 0.2s;
}

.p-timeline {
  width: max-content;
}

.p-scrolltop.p-link {
  background: var(--primary-blue);
}

/*.p-button {
  background: var(--primary-blue);
}
*/
.custom-scrolltop {
  position: absolute;
  bottom: 0px
}

.p-timeline-event-content,
.p-timeline-event-separator {
  background: var(--R2G-Dark-Blue, #162A3E);
}

.p-timeline-event-content {
  width: fit-content
}

.p-timeline-event-opposite {
  display: none
}

.p-inputtext {
  font-family: "franklin-gothic-condensed";
  font-size: 14px;
  font-style: normal;
}

.p-multiselect {
  background: none;
  border: 1px solid var(--p-dark-shade);
}

.p-multiselect-trigger>.p-icon-wrapper {
  color: white
}

.p-multiselect-chip .p-multiselect-token {
  font-family: "franklin-gothic-condensed";
  font-size: 14px;
  font-style: normal;
  background: var(--p-dark-shade);
  color: white
}

.p-multiselect-items .p-multiselect-item {
  font-family: "franklin-gothic-condensed";
  font-size: 14px;
  font-style: normal;
}

.p-button {
  font-family: "franklin-gothic-condensed";
  font-size: 14px;
  font-style: normal;
}

.p-paginator {
  font-family: "franklin-gothic-condensed";
  font-size: 14px;
  font-style: normal;
}

.p-component {
  font-family: "franklin-gothic-condensed";
}

.table-save {
  background-color: var(--goglobal-green);
}

.table-actions {
  background-color: var(--primary-blue);
}

thead>tr>th>p-sorticon>*>svg {
  color: white;
}

.p-panel .p-panel-header {
  background: var(--R2G-Dark-Blue, #162A3E);
  border-radius: 0px
}

.p-panel .p-panel-content {
  background: var(--R2G-Dark-Blue, #162A3E);
  border-radius: 0px
}

.p-datatable .p-datatable-header {
  padding: 0px;
}

.p-datatable .p-datatable-thead>tr>th {
  background: var(--R2G-Dark-Blue, #162A3E);
  color: var(--White, #FFF);
  min-height: 20px;
  padding: 8px 12px;
  overflow: hidden;
  font-family: "franklin-gothic-condensed";
  height: 20px;
  padding: 16px 8px;
  font-size: 14px;
  font-style: normal;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 50;
}

.p-datatable .p-datatable-tbody>tr>td {
  font-family: "franklin-gothic-condensed";
  min-height: 20px;
  padding: 16px 8px;
  font-size: 14px;
  font-style: normal;
  border-bottom: 4px solid var(--Off-White, #F4F4F4);
  align-self: center;
  white-space: nowrap;
}

.p-datatable .p-datatable-thead>tr>th {
  border: none;
  text-wrap-mode: nowrap;
}

.centered-text {
  text-align: center!important;
}

:root {
  --primary-color: #368EDE;
  --danger-color: #DC6868;
  --warn-color: #FFD34F;
  --neutral-color: #C2C2C2;
  --unknown-color: #343a40;
  --success-color: #36DE6F;
  --theme-unknown-bg: rgb(194, 194, 194);
}

body {
  height: 100%;
}

body {
  margin: 0px;
  font-family: "franklin-gothic-condensed";
}

:root {
  /* Root2Go CSS color theme variables */
  --dark-blue: #162a3e;
  --white: white;
  --hover-green: #d6f5da;
  --goglobal-green: #35a948;
  --dark-grey: #616161;
  --goglobal-dark-green: #18471f;
  --light-grey: #c2c2c2;
  --primary-blue: #368ede;
  --p-dark-shade: #214a70;
  --warning-red: #dc6868;
  --approval-green: #36de6f;
  --off-white: #faf9fd;
  --caution-yellow: #ffd34f;
  --overlay: rgba(0, 0, 0, .8);
  --db-lighter-shade: #407cb8;
  --black: black;
  --goglobal-mid-green: #246e30;
  --p-light-shade: #316fa8;
  --db-light-shade: #2b537a;
  --soft-red: #ffe9e9;
  --soft-blue: #cde7ff;
  /* Root2Go CSS size theme variables */
  --app-text-size: 14px;
  --paragraph-text-size: 16px;
  --mobile-font-small: 12px;
  --app-line-height: 1.1em;
  --paragraph-line-height: 1.5em;
  --heading-line-height: 1.1em;
  --h1: 36px;
  --h2: 32px;
  --h3: 28px;
  --h4: 24px;
  --h5: 20px;
  /* Material 3 design tokens  / variables */

  --mat-app-text-color: var(--dark-blue);
  --mdc-filled-text-field-container-color: var(--off-white, #faf9fd);
  --mdc-filled-text-field-active-indicator-height: 2px;
  --mdc-filled-text-field-indicator-color: var(--light-grey, #c2c2c2);
  --mat-form-field-container-text-font: "franklin-gothic-condensed";
  --mdc-filled-text-field-label-text-font: "franklin-gothic-condensed";
  --mdc-filled-text-field-label-text-color: var(--p-dark-shade, #214a70);
  --mat-form-field-container-height: 40px;
  --mat-form-field-focus-select-arrow-color: var(--primary-blue, #368ede);
  --mat-form-field-filled-with-label-container-padding-top: 12px;
  --mat-form-field-filled-with-label-container-padding-bottom: 4px;
  --mat-form-field-container-text-size: 14px;
  --mdc-filled-text-field-label-text-size: 14px;
  --mdc-filled-text-field-hover-label-text-color: #368ede;
  --mdc-icon-button-icon-color: #368ede;
  --mdc-filled-text-field-caret-color: var(--primary-blue: #368ede);
  --mdc-filled-text-field-focus-active-indicator-color: var(--primary-blue, #368ede);
  --mdc-filled-text-field-focus-label-text-color: var(--primary-blue, #368ede);
  --mdc-filled-text-field-container-color: var(--off-white, #faf9fd);
  --mdc-filled-text-field-disabled-container-color: rgba(26, 27, 31, 0.04);
  --mdc-filled-text-field-label-text-color: var(--dark-blue, #162a3e);
  --mat-list-active-indicator-shape: 4px;
  --mdc-filled-text-field-focus-active-indicator-color: var(--primary-blue: #368ede);
  --mdc-filled-text-field-caret-color: var(--primary-blue, #368ede);
  --mat-divider-color: var(--primary-blue);
  --mat-divider-width: 2px;
  --mat-paginator-container-text-color: var(--dark-blue);
  --mat-select-panel-background-color: var(--off-white);
  --mat-option-selected-state-label-text-color: var(--dark-blue);
  --mat-option-label-text-color: var(--dark-blue);
  --mat-option-hover-state-layer-color: #162a3e88;
  --mat-option-focus-state-layer-color: #162a3ec7;
  --mat-option-selected-state-layer-color: #316fa84b;
  --mat-option-label-text-font: "franklin-gothic-condensed";
  --mat-option-label-text-line-height: var(--app-line-height);
  --mat-option-label-text-size: var(--app-text-size);
  --mat-option-label-text-tracking: 0.006rem;
  --mat-option-label-text-weight: 400;
  --mdc-text-button-label-text-color: var(--white);
  --mdc-text-button-label-text-font: 'franklin-gothic-condensed';
  --mat-form-field-container-vertical-padding: 8px;
  --mat-select-trigger-text-size: var(--app-text-size, 14px);
  --mat-select-enabled-trigger-text-color: var(--dark-blue);
  --mat-select-trigger-text-font: "franklin-gothic-condensed";
  --mat-select-trigger-text-tracking: 0px;
  --mat-select-trigger-text-line-height: var(--app-line-height);
  --mat-full-pseudo-checkbox-selected-icon-color: var(--primary-blue);
  --mat-option-focus-state-layer-color: var(--light-grey);
  --mat-stepper-header-selected-state-icon-background-color: var(--primary-blue) !important;
  --mat-stepper-header-selected-state-icon-foreground-color: var(--white) !important;
  --mat-stepper-header-edit-state-icon-background-color: var(--primary-blue) !;
  --mat-stepper-header-edit-state-icon-foreground-color: var(--white) !important;
  --mat-table-row-item-label-text-font: "franklin-gothic-condensed" !important;
  --mdc-dialog-container-shape: 8px;
  --mdc-text-button-container-shape: 120px;
  --mdc-text-button-container-height: 40px;
  --mat-sidenav-content-background-color: var(--white);

  .mat-accent.mat-mdc-slide-toggle
{
  /* Default (Off) State - Yellow */
  --mdc-switch-selected-track-color: var(--caution-yellow);
  --mdc-switch-selected-handle-color: var(--white);
  --mdc-switch-selected-hover-track-color: var(--caution-yellow);
  --mdc-switch-selected-focus-track-color: var(--caution-yellow);
  --mdc-switch-selected-pressed-track-color: var(--caution-yellow);
  --mdc-switch-selected-hover-handle-color: var(--white);
  --mdc-switch-selected-focus-handle-color: var(--white);
  --mdc-switch-selected-pressed-handle-color: var(--white);
  --mdc-switch-selected-icon-color: var(--white);
  --mdc-switch-unselected-icon-color: var(--white);
  /* Toggled (On) State - Red */
  --mdc-switch-unselected-track-color: var(--goglobal-green);
  --mdc-switch-unselected-handle-color: var(--white);
  --mdc-switch-unselected-hover-track-color: var(--goglobal-green);
  --mdc-switch-unselected-focus-track-color: var(--goglobal-green);
  --mdc-switch-unselected-pressed-track-color: var(--goglobal-green);
  --mdc-switch-unselected-hover-handle-color: var(--white);
  --mdc-switch-unselected-focus-handle-color: var(--white);
  --mdc-switch-unselected-pressed-handle-color: var(--white);
  /* State Layer Colors (Control focus color) */
  --mdc-switch-selected-focus-state-layer-color: var(--caution-yellow);
  --mdc-switch-selected-hover-state-layer-color: var(--caution-yellow);
  --mdc-switch-selected-pressed-state-layer-color: var(--caution-yellow);
  --mdc-switch-unselected-focus-state-layer-color: var(--warning-red);
  --mdc-switch-unselected-hover-state-layer-color: var(--warning-red);
  --mdc-switch-unselected-pressed-state-layer-color: var(--warning-red);
}


  --mdc-switch-selected-focus-state-layer-color: var(--caution-yellow) !important;
  --mdc-switch-selected-handle-color: #ffffff !important;
  --mdc-switch-selected-hover-state-layer-color: var(--caution-yellow) !important;
  --mdc-switch-selected-pressed-state-layer-color: var(--caution-yellow) !important;
  --mdc-switch-selected-focus-handle-color: var(--white) !important;
  --mdc-switch-selected-hover-handle-color: var(--white) !important;
  --mdc-switch-selected-pressed-handle-color: var(--caution-yellow) !important;
  --mdc-switch-selected-focus-track-color: var(--caution-yellow) !important;
  --mat-standard-button-toggle-height: 1rem;
  --mat-badge-container-overlap-offset: -8px;
  --mat-badge-legacy-container-size: auto !important;
  --mdc-dialog-subhead-color: var(--primary-blue);
  --mdc-dialog-subhead-font: "franklin-gothic-condensed";
  --mdc-dialog-subhead-line-height: 1.3rem;
  --mdc-dialog-subhead-weight: bold;
  --mdc-dialog-subhead-tracking: 0px;
  --mdc-text-button-disabled-label-text-color: var(--white);
  --mdc-dialog-supporting-text-font: "franklin-gothic-condensed";

  .mat-accent.mat-mdc-slide-toggle {
    --mat-switch-label-text-color: #1a1b1f;
  }
}

.btm-panel-container {
  display: flex;
  gap: 16px;
  flex-direction: column;
  padding: 16px 0px;
  max-height: 350px;
  overflow-y: scroll;
}

.mat-mdc-button-touch-target {
  display: none;
}

.mat-drawer-inner-container::-webkit-scrollbar {
  display: none;
}

.mat-drawer-inner-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.mat-mdc-select-panel::-webkit-scrollbar {
  display: none;
}

.mat-mdc-select-panel {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.mat-mdc-text-field-wrapper {
  height: auto;
}

.mat-mdc-text-field-filled {
  background: transparent !important;
}

.mat-mdc-form-field-focus-overlay {
  background: transparent!important;
}

.mat-sidenav-content {
  padding: 20px 20px !important;
}

mat-form-field {
  width: 100% !important;
  max-width: 140px !important;
  box-sizing: border-box !important;
}

/* Material 3 button styles*/
.mdc-button__label {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.mat-mdc-form-field-flex {
  display: contents;
  align-items: baseline;
  box-sizing: border-box;
  width: 100%;
}

/* Material 3 table styles */
.mat-sort-header-content {
  text-align: left !important;
  font-weight: bold;
}

.mat-elevation-z2 {
  border-radius: 8px;
  overflow: hidden;
  overflow-x: scroll;
  position: relative;
}

.mat-mdc-row {
  font-family: "franklin-gothic-condensed" !important;
}

.mat-mdc-paginator {
  position: sticky;
  left: 0;
  right: 0;
}

.paragraph-wrap {
  max-width: 800px !important;
}

.disclaimer-text {
  font-size: 14px !important;
}

/* Material 3 typography text styles */
.app-backround {
  font-family: "franklin-gothic-condensed";
  font-size: var(--app-text-size, 14px);
  line-height: var(--app-line-height, 1rem);
  font-weight: 400;
  background-color: var(--off-white);
}

.app-backround p {
  font-family: "franklin-gothic-condensed";
  font-size: var(--paragraph-text-size, 16px);
  line-height: var(--paragraph-line-height, 1.5rem);
  font-weight: 400;
}

.app-backround h1 {
  font-size: var(--h1, 30px);
  font-weight: 600;
  font-family: "franklin-gothic-condensed";
  line-height: 1.2em;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0px;
}

.app-backround h2 {
  font-size: var(--h2, 26px);
  font-family: "franklin-gothic-condensed";
  line-height: var(--heading-line-height, 1.3em);
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1.2em;
  letter-spacing: 1px;
  margin: 0px;
}

.app-backround h3 {
  font-size: var(--h3, 22px);
  font-family: "franklin-gothic-condensed";
  line-height: var(--heading-line-height, 1.1rem);
  text-transform: uppercase;
  font-weight: 600;
  color: var(--dark-blue);
  margin: 0px;
  letter-spacing: 1px;
}

.app-backround h4 {
  font-size: var(--h4, 18px);
  font-family: "franklin-gothic-condensed";
  line-height: var(--heading-line-height, 1.3rem);
  text-transform: uppercase;
  font-weight: 600;
  color: var(--dark-blue, );
  letter-spacing: 0px;
  margin-block-start: 0px;
  margin-block-end: 0px;
}

.app-backround h5 {
  font-size: var(--h5, 20px);
  font-family: "franklin-gothic-condensed";
  line-height: var(--heading-line-height, 1.3rem);
  text-transform: uppercase;
  font-weight: 600;
  color: var(--primary-blue, #368ede);
  letter-spacing: 0px;
  margin-block-start: 0px;
  margin-block-end: 0px;
}

/* Material 3 dialog styles */
.mat-mdc-dialog-panel {
  height: auto !important;
}

.mat-dialog-content {
  width: 100% !important;
}

.mat-divider {
  width: 100%;
  background-color: var(--primary-blue);
  margin: 0px;
}

.indicator-btn {
  border: unset;
  background-color: unset;
  color: #316FA8;
  padding-top: 4px;
}

.indicator-btn-subbed {
  border: unset;
  color: white;
  padding-top: 4px;
  background: var(--goglobal-green, #35a948);
  border-radius: 8px;
}

.indicator-btn-unsubbed {
  border: unset;
  color: white;
  padding-top: 4px;
  border-radius: 8px;
  background-color: var(--warning-red, #dc6868);
}

/* Global page styles */
.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.filter-btn-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  align-self: stretch;
  gap: 16px
}

.filter-btn {
  color: var(--primary-blue, #368EDE) !important;
  font-family: "franklin-gothic-condensed";
  font-size: 14px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 1rem;
  border-radius: 120px;
  border: 2px solid var(--primary-blue, #368EDE) !important;
  background-color: #FFF;
  transition: all ease-in 0.3s;
}

.filter-btn-delete {
  color: var(--warning-red, #dc6868) !important;
  font-family: "franklin-gothic-condensed";
  font-size: 14px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 1rem;
  border-radius: 120px;
  border: 2px solid var(--warning-red, #dc6868) !important;
  background-color: #FFF;
  transition: all ease-in 0.3s;
}

.filter-icon-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.reset-icon {
  width: 24px;
  height: 24px;
  background-image: url(/assets/ResetIcon.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  vertical-align: middle;
  position: relative;
  transition: all ease-in 0.3s;
}

.template-btn-reset {
  color: var(--primary-blue) !important;
  font-family: "franklin-gothic-condensed";
  font-size: 14px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 1rem;
  border-radius: 120px;
  border: 2px solid var(--primary-blue) !important;
  background-color: #FFF;
  transition: all ease-in 0.3s;
}

.save-icon {
  width: 24px;
  height: 24px;
  background-image: url(/assets/FloppyDisk-green.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  vertical-align: middle;
  position: relative;
  transition: all ease-in 0.3s;
}

.save-btn:hover .save-icon {
  width: 24px;
  height: 24px;
  background-image: url(/assets/FloppyDisk-white.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  vertical-align: middle;
  position: relative;
  transition: all ease-in 0.3s;
}

.save-btn {
  color: #35A948 !important;
  font-family: "franklin-gothic-condensed";
  font-size: 14px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 1rem;
  border-radius: 120px;
  border: 2px solid #35A948 !important;
  background-color: #FFF;
  transition: all ease-in 0.3s;
}

.save-btn:hover {
  color: #fff !important;
  background-color: #35A948;
}

.filter-icon {
  width: 24px;
  height: 24px;
  background-image: url(/assets/Filters.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  vertical-align: middle;
  position: relative;
  transition: all ease-in 0.3s;
}

.filter-btn:hover .filter-icon {
  background-image: url(/assets/FiltersWhite.svg);
  width: 24px;
  height: 24px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  vertical-align: middle;
  position: relative;
}

.filter-btn:hover {
  color: white !important;
  background-color: #368EDE;
}

.filter-btn-delete:hover {
  color: white !important;
  background-color: #dc6868;
}

.filter-clear-icon {
  width: 24px;
  height: 24px;
  background-image: url(/assets/FiltersClear.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  vertical-align: middle;
  position: relative;
  transition: all ease-in 0.3s;
}

.filter-delete-icon {
  width: 24px;
  height: 24px;
  background-image: url(/assets/TrashSimple.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  vertical-align: middle;
  position: relative;
  transition: all ease-in 0.3s;
}

.filter-btn:hover .filter-clear-icon {
  background-image: url(/assets/FiltersClear-White.svg);
  width: 24px;
  height: 24px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  vertical-align: middle;
  position: relative;
}

.filter-btn-delete:hover .filter-delete-icon {
  background-image: url(/assets/TrashSimple-White.svg);
  width: 24px;
  height: 24px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  vertical-align: middle;
  position: relative;
}


.filter-clear-btn:hover {
  color: white !important;
  background-color: #368EDE;
}

.map-container {
  height: 600px;
  width: 100%;
  overflow: hidden;
}

.p-toast-top-right {
  top: 80px !important;
  right: 50px !important;
  width: fit-content;
  z-index: 2000;
}

.p-toast .p-toast-message .p-toast-message-content {
  padding: 0.5rem 1rem;
  border-width: 0 0 0 0;
}

.p-toast-message-content {
  display: flex;
  align-items: center;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
  margin: 0 0 0 1rem;
}

.p-toast-message-text {
  flex: 1 1 auto;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
  font-weight: 700;
  text-align: justify;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
  margin: 0;
}

/* Mobile styles */
@media (max-width: 768px) {
  .map-container {
    height: 200px;
    width: 100%;
    overflow: hidden;
  }

  .mat-sidenav-content {
    padding: 20px !important;
  }

  mat-form-field {
    width: 100% !important;
    min-width: 100px;
    max-width: 300px;
  }

  .filter-icon {
    width: 16px;
    height: 16px;
  }

  .divider-line {
    height: 5px;
    background: #368ede;
    width: 100%;
  }
}

.p-dialog .p-dialog-header {
  color: #368EDE !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.add-booking-btn {
  display: flex;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 120px;
  color: #FFF !important;
  background: var(--R2G-Main-Blue, #368EDE);
  transition: all ease-in 0.3s;
  border: 1px solid var(--R2G-Main-Blue, #368EDE);
  font-family: 'franklin-gothic-condensed';
  font-weight: 600;
}

.cancel-booking-btn {
  display: flex;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 120px;
  color: var(--warning-red) !important;
  background: #fff;
  transition: all ease-in 0.3s;
  border: 2px solid var(--warning-red) !important;
  font-family: 'franklin-gothic-condensed';
  font-weight: 600;
}

.cancel-booking-btn:hover {
  color: #fff !important;
  background: var(--warning-red);
}

.add-booking-btn:hover {
  background-color: #162A3E;
  border: 1px solid #162A3E;
}

.clear-filter {
  right: 12px;
  bottom: 70%;
  position: absolute;
  z-index: 1;
  border: unset;
  background: unset;
  padding: 0px;
  top: 30%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  color: var(--warning-red);
}

.clear-search {
  right: 0px;
  top: 50%;
  bottom: 50%;
  position: absolute;
  z-index: 1;
  border: unset;
  background: unset;
  padding: 0px;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  color: var(--warning-red);
}

.mat-sort-header-arrow {
  color: white !important;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1* var(--bs-gutter-y));
  margin-right: calc(-.5* var(--bs-gutter-x));
  margin-left: calc(-.5* var(--bs-gutter-x));
}

.mat-badge {
  --mat-badge-background-color: var(--warning-red);
  --mat-badge-text-color: var(--white);
  --mat-badge-disabled-state-background-color: var(--dark-grey);
  --mat-badge-disabled-state-text-color: var(--white);
}

/* For mobile phones: */
/*[class*="col-"] {
  width: 100%;
}*/

@media only screen and (min-width: 768px) {


  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-2-schedules {
    flex: 1 1 250px;
    max-width: 360px;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}

.row>* {
  box-sizing: border-box;
  flex-shrink: 0;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x)* .5);
  padding-left: calc(var(--bs-gutter-x)* .5);
  margin-top: var(--bs-gutter-y);
}

.add-booking-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.runReportButton {
  width: auto;
  display: flex;
  max-height: 40px;
  padding: 8px 16px;
  align-items: center;
  gap: 12px;
  border-radius: 4px 4px 0px 0px;
  background: var(--R2G-Main-Blue, #368EDE);
  border: none;
  color: var(--White, #FFF);
  font-family: "franklin-gothic-condensed";
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.runReportButton:hover {
  background-color: #162A3E;
}

.runReportButton:active {
  background-color: #162A3E;
  transform: scale(1.1);
  transition: .1s;
}

.runReportButton:active {
  background-color: #162A3E;
  transform: scale(1.1);
  transition: .1s;
}

mat-form-field {
  width: none !important;
  max-width: none !important;
  box-sizing: border-box !important;
}

.tab-menu {
  display: flex !important;
  align-items: flex-start !important;
  justify-content: space-around !important;
  width: 100% !important;
  gap: 4px !important;
  color: var(--goglobal-green, #35A948) !important;
  background-color: var(--white) !important;
  border-radius: 8px !important;
  border-color: var(--goglobal-green, #35A948) !important;
  border-style: solid !important;
  border-width: 1px !important;
}

.tab-menu-item {
  color: var(--goglobal-green, #35A948) !important;
  display: flex !important;
  justify-content: center;
  align-items: center !important;
  width: 100% !important;
  font-family: 'franklin-gothic-condensed' !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  transition: all ease 0.3;
}

.tab-menu-item:hover {
  background-color: var(--goglobal-green, #35A948);
  border-radius: 4px !important;
  color: var(--white) !important;
}

.tab-menu-item>button {
  padding: 8px 12px;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle-appearance-standard+.mat-button-toggle-appearance-standard {
  border-left: unset !important;
  box-sizing: border-box;
}

.mat-button-toggle-appearance-standard.mat-button-toggle-checked {
  color: #fff !important;
  background-color: var(--GoGlobal-Green, #35A948) !important;
  border-radius: 8px !important;
}

.mat-button-toggle-group {
  box-sizing: border-box;
}

.tab-content {
  width: 100% !important;
}

.mat-accent.mat-mdc-checkbox {
  --mdc-checkbox-selected-checkmark-color: #ffffff;
  --mdc-checkbox-selected-focus-icon-color: #368ede;
  --mdc-checkbox-selected-hover-icon-color: #368ede;
  --mdc-checkbox-selected-icon-color: #368ede;
  --mdc-checkbox-selected-focus-state-layer-color: #368ede;
  --mdc-checkbox-selected-hover-state-layer-color: #368ede;
  --mdc-checkbox-unselected-pressed-state-layer-color: #368ede;
  --mat-checkbox-disabled-label-color: rgba(26, 27, 31, 0.38);
  --mat-checkbox-label-text-color: #1a1b1f;
}

.indicator-btn-alerts-unread {
  border: unset;
  color: white;
  padding-top: 4px;
  background: var(--warning-red, #dc6868);
  border-radius: 8px;
  margin-left: 2px;
  margin-right: 2px;
}

.custom-mat-tooltip {
  .mdc-tooltip__surface {
    color: white !important;
    background-color: #2b537a !important;
    white-space: pre-line;
  }
}

.custom-mat-underline {
  .mdc-text-field--filled:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-line-ripple::before {
    border-bottom-color: green;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-line-ripple::before {
    border-bottom-color: green;
  }
}

.col-md-2-schedules {
  flex: 1 1 250px;
  max-width: 360px;
}

.loading-cell {
  color: transparent;
  animation: shimmer 2s infinite linear;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
}

.table-filter-container {
  justify-content: flex-end;
  display: flex;
  position: sticky;
  top: 0;
  z-index: 101;
}

.export-button {
  background-color: #faf9fd;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5) !important;
  border-bottom: 2px solid;
  border-bottom-color: var(--mdc-filled-text-field-hover-active-indicator-color, var(--mat-app-on-surface));
}


@media (min-width: 481px) and (max-width: 768px) {
  .p-datatable .p-datatable-tbody > tr > td {
    font-family: "franklin-gothic-condensed";
    min-height: 20px;
    padding: 8px 8px;
    font-size: 14px;
    font-style: normal;
    border-bottom: none;
    align-self: center;
    white-space: nowrap;
  }

}

  @media (max-width: 480px) {

    .p-datatable .p-datatable-tbody > tr > td {
      font-family: "franklin-gothic-condensed";
      min-height: 20px;
      padding: 8px 8px;
      font-size: 14px;
      font-style: normal;
      border-bottom: none;
      align-self: center;
      white-space: nowrap;
    }

    h1 {
      font-size: 30px !important;
      text-wrap-mode: nowrap;
    }

    .header-title-wrapper {
      row-gap: 16px;
    }

    .tab-menu-item > button {
      padding: 8px 6px;
    }
  }

  .border-table-header {
    border-radius: 0;
  }

  .mat-mdc-dialog-title {
    padding: unset !important;
  }

  .dialog-confirm-button {
    display: flex;
    flex-direction: row;
    padding: 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 120px;
    border: none;
    background-color: var(--primary-blue, #368ede) !important;
    color: var(--white);
    font-family: inherit;
    font-size: 16px;
    cursor: pointer;
    transition: all ease-in 0.3s;
  }

  .dialog-deny-button {
    display: flex;
    flex-direction: row;
    padding: 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 120px;
    border: none;
    background-color: #de364c !important;
    color: var(--white);
    font-family: inherit;
    font-size: 16px;
    cursor: pointer;
    transition: all ease-in 0.3s;
  }

.blur-left,
.blur-right {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 20px;
  pointer-events: none;
  z-index: 1;
}

.blur-left {
  left: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
}

.blur-right {
  right: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
}
